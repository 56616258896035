import React from 'react';

const FAQ = () => {
  
  
  
  return  (
    <div className="page-faq">
    
    cooming sooon!!!!
    </div>
    );
};

export default FAQ;