import React from 'react';


const Kalkulasi = () => {
  
  
  
  
  return (
    <div className="KalkulasiPage">
      cooming soon !!!!!..
    </div>
    );
};

export default Kalkulasi;