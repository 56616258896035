import React from 'react';

const Gallery = () => {
  
  
  
  return  (
    <div className="Galery-page">
    
    cooming sooon!!!!
    </div>
    );
};

export default Gallery;